import "core-js/modules/es.array.splice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "crmmap"
  }, [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "ChangeUserToAktModal",
      "adaptive": true,
      "reset": true,
      "width": "520",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenCuta
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Haupt-SV des Aktes ändern"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ChangeUserToAktModal');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.cutaData.title))]), _vm.cutaData._id ? _c('span', [_c('ChangeUserToAkt', {
    attrs: {
      "aktId": _vm.cutaData.id,
      "mainUserId": _vm.cutaData.assignedAppraiserContactId
    },
    on: {
      "userChanged": _vm.userChanged
    }
  })], 1) : _vm._e()])]), _c('FilterMap', {
    attrs: {
      "filtercounts": _vm.filteredMapItemsList.length
    }
  }), _c('div', {
    staticClass: "map-sidebar"
  }, [_c('label', {
    staticClass: "automap tac padding"
  }, [_c('gmap-autocomplete', {
    staticClass: "input",
    attrs: {
      "placeholder": "Adresse in Karte finden 🔍"
    },
    on: {
      "place_changed": _vm.setPlace
    }
  })], 1), _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "link bold",
    on: {
      "click": function click($event) {
        _vm.openrouter = !_vm.openrouter;
      }
    }
  }, [_vm._v("Routenplaner ein/ausblenden")])]), _c('div', {
    staticClass: "openrouteplaner",
    class: {
      _open: _vm.openrouter
    }
  }, [_c('h5', {
    staticClass: "bold"
  }, [_vm._v("Route eingegeben")]), _c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.callPlaner();
      }
    }
  }, [_vm._v("Berechne Route")]), _vm.routeresp.sum ? _c('div', {
    staticClass: "btn _small _red",
    on: {
      "click": function click($event) {
        return _vm.ReInitMapRoute();
      }
    }
  }, [_vm._v("Reset")]) : _vm._e(), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.ABC[0]) + "  ")]), !_vm.Planer.start.bez ? _c('span', {
    staticClass: "bold"
  }, [_vm._v("Auf der Karte auswählen")]) : _vm.Planer.start.bez ? _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.Planer.start.bez))]) : _vm._e()]), _vm._l(_vm.Planer.waypoints, function (wp, ind) {
    return _c('span', [_c('p', {
      staticClass: "bold"
    }, [_vm._v("  " + _vm._s(_vm.ABC[ind + 1]) + "   " + _vm._s(wp.bez)), _c('span', {
      staticClass: "closer _hover",
      on: {
        "click": function click($event) {
          return _vm.Planer.waypoints.splice(ind, 1);
        }
      }
    }, [_vm._v("  "), _c('i', {
      staticClass: "icon-times inline"
    })])])]);
  }), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.ABC[1 + _vm.Planer.waypoints.length]) + "  ")]), !_vm.Planer.end.bez ? _c('span', {
    staticClass: "bold"
  }, [_vm._v("Auf der Karte auswählen")]) : _vm.Planer.end.bez ? _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.Planer.end.bez))]) : _vm._e()]), _c('br'), _vm.routeresp.sum ? _c('span', [_c('h5', {
    staticClass: "bold"
  }, [_vm._v("Berechnet: " + _vm._s(parseInt(_vm.routeresp.sum.distance / 1000)) + " km / " + _vm._s(parseInt(_vm.routeresp.sum.duration / 60)) + " min")]), _vm.routeresp.routes ? _c('span', _vm._l(_vm.routeresp.routes, function (rut, idr) {
    return _c('p', [_vm._v(_vm._s(_vm.ABC[idr]) + "-" + _vm._s(_vm.ABC[idr + 1]) + ":   " + _vm._s(rut.distance.text) + " - " + _vm._s(rut.duration.text))]);
  }), 0) : _vm._e()]) : _vm._e()], 2), _c('div', {
    staticClass: "wz-tabs noselect"
  }, [_c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.sortListBy == 'consenseNr'
    },
    on: {
      "click": function click($event) {
        _vm.sortListBy = 'consenseNr';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Akt")]), _c('div', {
    staticClass: "wz-tab",
    class: {
      _selected: _vm.sortListBy == 'dateHr'
    },
    on: {
      "click": function click($event) {
        _vm.sortListBy = 'dateHr';
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Bes.Datum")])]), _vm.sortListBy == 'dateHr' ? _c('ul', {
    staticClass: "maplist condensed scrolltocontainer"
  }, _vm._l(_vm.sortedSideListItems, function (mapItem, mapIdx) {
    return mapItem.date ? _c('li', {
      staticClass: "pointer over"
    }, [_vm.sortListBy == 'dateHr' ? _c('div', {
      staticClass: "datehead"
    }, [_vm._v(_vm._s(_vm.$dayjs(mapItem.date, 'YYYY-MM-DD').format(_vm.MAIN_ONLY_DATE_FORMAT)))]) : _vm._e(), _c('ul', {
      staticClass: "maplistwithoutdate"
    }, _vm._l(mapItem.list, function (mark, index) {
      return _c('li', {
        staticClass: "mapitem",
        class: {
          _active: mark.commissionNr === _vm.selmap,
          nodata: (mark.loc || {}).hasCoords !== 100000000
        }
      }, [_c('div', [_c('span', {
        staticClass: "projekt-nummer"
      }, [_c('router-link', {
        attrs: {
          "to": "/crmakt/".concat(mark.title)
        }
      }, [_c('span', [_vm._v(_vm._s(mark.commissionNr)), mark.shortName ? _c('span', {
        staticClass: "color-blue"
      }, [_c('br'), _vm._v(_vm._s(mark.shortName))]) : _vm._e()])])], 1), _c('span', {
        staticClass: "ma",
        on: {
          "click": function click($event) {
            _vm._isAdmin && _vm.$modal.show('ChangeUserToAktModal', mark);
          }
        }
      }, [_vm._v(_vm._s(mark.assignedAppraiserName || 'kein Bearbeiter!') + "  "), _c('span', {
        staticClass: "status"
      }, [_vm._v(_vm._s((_vm.status[mark.statusOpt + ''] || {}).substring(0, 2)))])]), mark.visitDate != '' ? _c('span', {
        staticClass: "besdate bold"
      }, [_vm._v(_vm._s(_vm.$dayjs(mark.visitDate).format(_vm.MAIN_DATE_FORMAT_CONDENSED)))]) : _vm._e(), _c('span', {
        staticClass: "adresse"
      }, [mark.visitCountry !== 'Österreich' ? _c('span', [_vm._v(_vm._s(mark.visitCountry) + " ")]) : _vm._e(), _c('span', [_vm._v(_vm._s(mark.visitCity))])]), _c('span', {
        staticClass: "adresse"
      }, [_vm._v(_vm._s(mark.visitPostal) + " " + _vm._s(mark.visitStreet))]), _c('small', {
        staticClass: "ageber bold"
      }, [_vm._v(_vm._s(mark.insurantName))]), _c('span', {
        staticClass: "sparte"
      }, [_vm._v(_vm._s(_vm.sparten['sparten'][mark.insuranceLineOpt]) + " -"), _c('span', [_vm._v(_vm._s(_vm.sparten['details'][mark.insuranceLineDetailsOpt]) + " -")]), _c('span', [_vm._v(_vm._s(_vm.sparten['art'][mark.damageKindOpt]))])])])]);
    }), 0)]) : _vm._e();
  }), 0) : _vm._e(), _vm.sortListBy == 'consenseNr' ? _c('ul', {
    staticClass: "maplist condensed scrolltocontainer"
  }, _vm._l(_vm.filteredMapItemsList, function (mark, index) {
    return _c('li', {
      staticClass: "mapitem",
      class: {
        _active: mark.commissionNr === _vm.selmap,
        nodata: (mark.loc || {}).hasCoords !== 100000000
      }
    }, [_c('div', [_c('span', {
      staticClass: "projekt-nummer"
    }, [_c('router-link', {
      attrs: {
        "to": "/crmakt/".concat(mark.title)
      }
    }, [_c('span', [_vm._v(_vm._s(mark.commissionNr)), mark.shortName ? _c('span', {
      staticClass: "color-blue"
    }, [_c('br'), _vm._v(_vm._s(mark.shortName))]) : _vm._e()])])], 1), _c('span', {
      staticClass: "ma",
      on: {
        "click": function click($event) {
          _vm._isAdmin && _vm.$modal.show('ChangeUserToAktModal', mark);
        }
      }
    }, [_vm._v(_vm._s(mark.assignedAppraiserName || 'kein Bearbeiter!') + "  "), _c('span', {
      staticClass: "status"
    }, [_vm._v(_vm._s((_vm.status[mark.statusOpt + ''] || {}).substring(0, 2)))])]), mark.visitDate != '' ? _c('span', {
      staticClass: "besdate bold"
    }, [_vm._v(_vm._s(_vm.$dayjs(mark.visitDate).format('DD.MM.YY - HH:mm')))]) : _vm._e(), _c('span', {
      staticClass: "adresse"
    }, [mark.visitCountry !== 'Österreich' ? _c('span', [_vm._v(_vm._s(mark.visitCountry) + " ")]) : _vm._e(), _c('span', [_vm._v(_vm._s(mark.visitCity))])]), _c('span', {
      staticClass: "adresse"
    }, [_vm._v(_vm._s(mark.visitPostal) + " " + _vm._s(mark.visitStreet))]), _c('small', {
      staticClass: "ageber bold"
    }, [_vm._v(_vm._s(mark.insurantName))]), _c('span', {
      staticClass: "sparte"
    }, [_vm._v(_vm._s(_vm.sparten['sparten'][mark.insuranceLineOpt]) + " -"), _c('span', [_vm._v(_vm._s(_vm.sparten['details'][mark.insuranceLineDetailsOpt]) + " -")]), _c('span', [_vm._v(_vm._s(_vm.sparten['art'][mark.damageKindOpt]))])])])]);
  }), 0) : _vm._e()]), _c('GoogleMapsV2', {
    ref: "GoogleMaps",
    attrs: {
      "markerSvObj": _vm.markerSvObj,
      "markerAktObj": _vm.markerAktObj
    },
    on: {
      "clickMarker": _vm.clickMarker,
      "wayPointsStart": _vm.wayPointsStart,
      "wayPointsMidroute": _vm.wayPointsMidroute,
      "wayPointsEnd": _vm.wayPointsEnd
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };