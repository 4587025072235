import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.push.js";
import { mapGetters, mapState, mapActions } from "vuex";
import _ from "underscore";
import GoogleMapsV2 from "@/components/map/GoogleMapsV2.vue";
import ChangeUserToAkt from "../../views/Crm/CrmAkt/ChangeUserToAkt";
var directionsService = null;
var directionsDisplay = null;
var self;
export default {
  name: "MapPage",
  components: {
    GoogleMapsV2: GoogleMapsV2,
    ChangeUserToAkt: ChangeUserToAkt
  },
  data: function data() {
    return {
      Planer: {
        start: {
          loc: null,
          bez: ""
        },
        waypoints: [],
        end: {
          loc: null,
          bez: ""
        }
      },
      ABC: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"],
      //prepared waypoints short-names

      openrouter: false,
      sortListBy: "dateHr",
      map: {},
      showmap: false,
      center: {
        lat: 48.284987,
        lng: 16.367482
      },
      maincenter: {
        lat: 48.284987,
        lng: 16.367482
      },
      selmap: "",
      reverse: false,
      mapsorter: "akt",
      google: {},
      allmarker: {},
      zoom: 11,
      month: "",
      routeresp: {},
      cutaData: {}
    };
  },
  // watch: {},
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(["globalSearch", "user",
  // "projects",
  "commissions", "folder", "svlist", "sparten", "status", "statusColors", "combinedStatus", "kindOptions"])), mapGetters(["commissionsList", "_svNamesByCrmContactId", "_fbUsersSelectingArr"])), {}, {
    filterData: function filterData() {
      return (self._currentUser || {}).userFilter || {};
    },
    markerSvObj: function markerSvObj() {
      var svList = self.svlist;
      var resultObj = {};
      if (self.filterData.isShowingSvInMap) {
        for (var svIdx in svList) {
          var sv = svList[svIdx];
          if (sv.loc && sv.firstname && (!self.filterData.svInMapList.length || self.filterData.svInMapList.includes(sv.crmContactId))) {
            resultObj[sv.crmContactId] = {
              icon: {
                url: "/marker/marker-black.svg",
                anchor: new window.google.maps.Point(10, 24),
                labelOrigin: new google.maps.Point(10, 10)
              },
              label: {
                text: (sv.firstname || "-")[0] + (sv.lastname || "-")[0],
                fontSize: "12px",
                color: "#fff"
              },
              position: sv.loc,
              sv: sv
            };
          }
        }
      }
      return resultObj;
    },
    // allowedMapStatus() {
    //   let allowedMapStatus = [];
    //   this.combinedStatus.forEach( (el) => (allowedMapStatus = [...allowedMapStatus, ...el.options]) );
    //   return allowedMapStatus
    // },
    allowedMapStatus: function allowedMapStatus() {
      var allowedMapStatus = [];
      // this.combinedStatus.forEach( (el) => (allowedMapStatus = [...allowedMapStatus, ...el.options]) );
      var combinedStatus = this.combinedStatus;
      for (var key in combinedStatus) {
        var el = combinedStatus[key];
        allowedMapStatus = [].concat(_toConsumableArray(allowedMapStatus), _toConsumableArray(el.options));
      }
      // this.combinedStatus.forEach( (el) => (allowedMapStatus = [...allowedMapStatus, ...el.options]) );
      return allowedMapStatus;
    },
    allowedFilteredStatus: function allowedFilteredStatus() {
      var cStatus = this.filterData.combinedStatusFilter || [];
      var combinedStatus = this.combinedStatus;
      var allowedFilteredStatus = [];
      var _iterator = _createForOfIteratorHelper(cStatus),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _combinedStatus$idx;
          var idx = _step.value;
          allowedFilteredStatus = [].concat(_toConsumableArray(allowedFilteredStatus), _toConsumableArray(((_combinedStatus$idx = combinedStatus[idx]) === null || _combinedStatus$idx === void 0 ? void 0 : _combinedStatus$idx.options) || []));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return allowedFilteredStatus;
    },
    markerAktObj: function markerAktObj() {
      var mapList = self.filteredMapItemsList;
      var resultObj = {};
      var statusColors = self.statusColors;
      // const combinedStatus = self.combinedStatus;
      // const allowedMapStatus = this.allowedMapStatus;

      if (window.google) for (var mapMarkerIdx in mapList) {
        var _mapMarker$loc;
        var mapMarker = mapList[mapMarkerIdx];
        if (mapMarker.commissionNr && ((_mapMarker$loc = mapMarker.loc) === null || _mapMarker$loc === void 0 ? void 0 : _mapMarker$loc.hasCoords) === 100000000) {
          var color = statusColors[mapMarker.statusOpt + "" || "default"];
          resultObj[mapMarkerIdx] = {
            icon: {
              url: "/marker/marker-".concat(color, ".svg"),
              anchor: new window.google.maps.Point(10, 24),
              labelOrigin: new google.maps.Point(10, 10)
            },
            position: mapMarker.loc,
            content: mapMarker
          };
        }
      }
      return resultObj;
    },
    preFilteredMapItems: function preFilteredMapItems() {
      // let preFilteredMapStatus = Object.keys(self.statusColors || {}) || [];
      // console.log("preFilteredMapStatus", preFilteredMapStatus);
      var allowedMapStatus = this.allowedMapStatus;
      var commissions = this.commissionsList.filter(function (el) {
        return allowedMapStatus.includes(el.statusOpt + "");
      });
      // console.log("commissions", commissions);
      return commissions;
    },
    filteredMapItemsList: function filteredMapItemsList() {
      var _this = this;
      var commissionsList = this.preFilteredMapItems;
      var search = this.globalSearch.toLowerCase();
      var allowedsvInMapList = this.filterData.svInMapList || [];
      // let allowedStatus = this.filterData.statusFilter || [];
      var allowedFilteredStatus = this.allowedFilteredStatus || [];
      var allowedArt = this.filterData.artFilter || Object.keys(this.kindOptions);
      var resultArr = commissionsList.filter(function (mapItem) {
        var isInDateRange = true;
        if (_this.filterData.isDateFilter) {
          isInDateRange = _this.$dayjs(mapItem.visitDate || 0) > _this.$dayjs(_this.filterData.dateFilterFrom) && _this.$dayjs(mapItem.visitDate || 0) < _this.$dayjs(_this.filterData.dateFilterTo).add(1, "day");
        }
        var isInSearch = !search || JSON.stringify(Object.values(mapItem)).toLowerCase().includes(search);
        var isAllowedSv = !allowedsvInMapList.length || allowedsvInMapList.includes(mapItem.assignedAppraiserContactId);
        // const isAllowedStatus = allowedStatus.includes(mapItem.statusOpt + "");
        var isAllowedStatus = allowedFilteredStatus.includes(mapItem.statusOpt + "");
        var isAllowedArt = !allowedArt.length || allowedArt.includes(mapItem.kindOpt + "");
        // console.log('allowedArt', allowedArt)
        // return isInDateRange && isInSearch && isAllowedStatus
        return isInDateRange && isInSearch && isAllowedSv && isAllowedStatus && isAllowedArt;
      });
      resultArr = _.sortBy(resultArr, "consenseNr");
      this.reverse && resultArr.reverse();
      // console.log('filteredMapItemsList',resultArr);
      return resultArr;
    },
    sortedSideListItems: function sortedSideListItems() {
      var filteredMapItemsList = this.filteredMapItemsList;
      var resultObj = {};
      var _iterator2 = _createForOfIteratorHelper(filteredMapItemsList),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var mapItem = _step2.value;
          mapItem.dateHr = mapItem.visitDate ? this.$dayjs(mapItem.visitDate || "").format("YYYY-MM-DD") : "";
          if (!resultObj[mapItem.dateHr]) resultObj[mapItem.dateHr] = [];
          resultObj[mapItem.dateHr].push(mapItem);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      var resultArr = [];
      for (var date in resultObj) resultArr.push({
        date: date,
        list: resultObj[date]
      });
      resultArr = _.sortBy(resultArr, "date");
      this.reverse && resultArr.reverse();
      return resultArr;
    }
  }),
  beforeCreate: function beforeCreate() {
    self = this;
  },
  mounted: function mounted() {
    var _this2 = this;
    setTimeout(function () {
      directionsService = new google.maps.DirectionsService();
      directionsDisplay = new google.maps.DirectionsRenderer();
      if (_this2.$refs.GoogleMaps.$refs.themap) directionsDisplay.setMap(_this2.$refs.GoogleMaps.$refs.themap.$mapObject);
    }, 2000); // google might take some time to load - todo: better usr defer
  },
  methods: _objectSpread(_objectSpread({}, mapActions(["_fetchCrmProjects"])), {}, {
    clickMarker: function clickMarker(e) {
      this.selmap = e.commissionNr;
      this.scrolltoitem();
    },
    wayPointsStart: function wayPointsStart(e) {
      console.log(e);
      this.Planer.start.loc = e.loc;
      this.Planer.start.bez = e.bez;
    },
    wayPointsMidroute: function wayPointsMidroute(e) {
      console.log(e);
      this.Planer.waypoints.push(e);
      // this.Planer.waypoints.push({ location: e.loc, stopover: true, bez: 'Marker' })
    },
    wayPointsEnd: function wayPointsEnd(e) {
      console.log(e);
      this.Planer.end.loc = e.loc;
      this.Planer.end.bez = e.bez;
    },
    userChanged: function userChanged(e) {
      console.log("userChanged");
      this._fetchCrmProjects();
      console.log(e);
    },
    callPlaner: function callPlaner() {
      // window.LOG.log("callPlaner");
      if (self.Planer.start && self.Planer.end) {
        var waypoints = JSON.parse(JSON.stringify(self.Planer.waypoints));
        for (var wp in waypoints) {
          delete waypoints[wp].bez;
        }
        window.LOG.log(waypoints);
        directionsService.route({
          origin: self.Planer.start.loc,
          //document.getElementById('start').value,
          destination: self.Planer.end.loc,
          //document.getElementById('end').value
          waypoints: waypoints,
          optimizeWaypoints: false,
          travelMode: "DRIVING"
        }, function (response, status) {
          if (status === "OK") {
            directionsDisplay.setDirections(response);
            var duration = 0;
            var distance = 0;
            for (var r in response.routes[0].legs) {
              duration += parseInt(response.routes[0].legs[r].duration.value);
              distance += parseInt(response.routes[0].legs[r].distance.value);
            }
            self.routeresp = {
              routes: response.routes[0].legs,
              sum: {
                distance: distance,
                duration: duration
              }
            };
          } else {
            window.LOG.log("Directions request failed due to " + status);
          }
        });
      }
    },
    ReInitMapRoute: function ReInitMapRoute() {
      if (directionsDisplay != null) {
        directionsDisplay.setMap(null);
        directionsDisplay = null;
      }
      directionsDisplay = new google.maps.DirectionsRenderer();
      if (this.$refs.GoogleMaps.$refs.themap) directionsDisplay.setMap(this.$refs.GoogleMaps.$refs.themap.$mapObject);
      self.routeresp = {};
      this.Planer = {
        start: {
          loc: null,
          bez: ""
        },
        waypoints: [],
        end: {
          loc: null,
          bez: ""
        }
      };
    },
    setPlace: function setPlace(place) {
      console.log("setPlace", place);
      if (place.geometry) {
        var center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.$refs.GoogleMaps.center = center;
        this.$refs.GoogleMaps.maincenter = center;
        this.$refs.GoogleMaps.mainMarker.center = center;
        this.$refs.GoogleMaps.zoom = 13;
      }
    },
    SortForMap: function SortForMap(th) {
      if (self.mapsorter === th) {
        self.reverse = !self.reverse;
      } else {
        self.mapsorter = th;
        self.reverse = false;
      }
    },
    // showonmap(index) { },
    // mouseOut(index) { },
    // mouseOver(index) { },
    scrolltoitem: function scrolltoitem() {
      setTimeout(function () {
        var options = {
          container: ".scrolltocontainer",
          easing: "ease",
          offset: -90,
          cancelable: true,
          x: false,
          y: true
        };
        self.$scrollTo("._active", 300, options);
      }, 100);
    },
    reverseObject: function reverseObject(object) {
      var newObject = {};
      var keys = [];
      for (var key in object) {
        keys.push(key);
      }
      for (var i = keys.length - 1; i >= 0; i--) {
        var value = object[keys[i]];
        newObject[keys[i]] = value;
      }
      return newObject;
    },
    beforeOpenCuta: function beforeOpenCuta(e) {
      this.cutaData = e.params;
    }
  })
};