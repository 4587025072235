import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "map-view"
  }, [_c('gmap-map', {
    ref: "themap",
    attrs: {
      "id": "map",
      "center": _vm.maincenter,
      "zoom": _vm.zoom,
      "options": {
        styles: _vm.mapStyle
      }
    }
  }, [_c('gmap-info-window', {
    attrs: {
      "options": {},
      "position": _vm.infoWinPos,
      "opened": _vm.infoWinOpen
    },
    on: {
      "closeclick": function closeclick($event) {
        _vm.infoWinOpen = false;
      }
    }
  }, [_vm.cInfoContent ? _c('div', [_vm.cInfoContent.title ? _c('div', [_c('router-link', {
    attrs: {
      "to": "/crmakt/".concat(_vm.cInfoContent.title)
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.cInfoContent.art) + " " + _vm._s(_vm.cInfoContent.commissionNr)), _vm.cInfoContent.shortName ? _c('span', {
    staticClass: "color-blue"
  }, [_c('br'), _vm._v(_vm._s(_vm.cInfoContent.shortName))]) : _vm._e()])]), _c('p', [_vm._v(_vm._s(_vm.status[_vm.cInfoContent.statusOpt]))]), _c('p', [_vm._v("Bearbeiter:"), _vm._isAdmin ? _c('span', {
    staticClass: "bold"
  }, [_c('a', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ChangeUserToAktModal', _vm.cInfoContent);
      }
    }
  }, [_vm._v(_vm._s(_vm.cInfoContent.assignedAppraiserName || 'kein Bearbeiter'))])]) : _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.cInfoContent.assignedAppraiserName))])]), _vm.cInfoContent.visitdatum ? _c('p', {
    staticClass: "bold"
  }, [_vm._v("Besichtigung am: " + _vm._s(_vm.$dayjs(_vm.cInfoContent.visitdatum).format('DD.MM.YYYY - HH:mm')))]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.cInfoContent.visitAddress))]), _c('p', [_c('small', [_vm._v(_vm._s(_vm.sparten['sparten'][_vm.cInfoContent.insuranceLineOpt + '']) + " -"), _c('span', [_vm._v(_vm._s(_vm.sparten['details'][_vm.cInfoContent.insuranceLineDetailsOpt + '']) + " -")]), _c('span', [_vm._v(_vm._s(_vm.sparten['art'][_vm.cInfoContent.damageKindOpt + '']))])])]), _c('br'), _c('p', [_vm._v("VN: " + _vm._s(_vm.cInfoContent.insureeName) + ", " + _vm._s(_vm.cInfoContent.insureeTel))]), _c('br'), _c('hr'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsStart({
          loc: _vm.cInfoContent.loc,
          bez: "".concat(_vm.cInfoContent.commissionNr)
        });
      }
    }
  }, [_vm._v("Start für Route")]), _c('br'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsMidroute({
          location: _vm.cInfoContent.loc,
          stopover: true,
          bez: "".concat(_vm.cInfoContent.commissionNr)
        });
      }
    }
  }, [_vm._v("Zum Weg hinzufügen")]), _c('br'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsEnd({
          loc: _vm.cInfoContent.loc,
          bez: "".concat(_vm.cInfoContent.commissionNr)
        });
      }
    }
  }, [_vm._v("Ende für Route")])], 1) : _vm.cInfoContent.ismark ? _c('div', [_c('h4', [_vm._v("Marker")]), _c('br'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsStart({
          loc: _vm.cInfoContent.loc,
          bez: "Marker"
        });
      }
    }
  }, [_vm._v("Start für Route")]), _c('br'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsMidroute({
          location: _vm.cInfoContent.loc,
          stopover: true,
          bez: "Marker"
        });
      }
    }
  }, [_vm._v("Zum Weg hinzufügen")]), _c('br'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsEnd({
          loc: _vm.cInfoContent.loc,
          bez: "Marker"
        });
      }
    }
  }, [_vm._v("Ende für Route")])]) : _vm.cInfoContent.firstname ? _c('div', [_c('div', [_c('h4', [_vm._v(_vm._s(_vm.cInfoContent.firstname) + " " + _vm._s(_vm.cInfoContent.lastname) + " " + _vm._s(_vm.cInfoContent.titel))]), _c('p', [_vm._v(_vm._s(_vm.cInfoContent.plzba) + " " + _vm._s(_vm.cInfoContent.ortba))]), _c('p', [_vm._v(_vm._s(_vm.cInfoContent.strasseba))]), _c('p', [_vm._v("email: " + _vm._s(_vm.cInfoContent.email))]), _c('p', [_vm._v("mobil: " + _vm._s(_vm.cInfoContent.mobil))]), _c('p', [_vm._v("tel: " + _vm._s(_vm.cInfoContent.tel))]), _c('div', {
    staticClass: "factorb"
  }, [_vm._v(_vm._s(_vm.cInfoContent.commissionNrs))]), _c('div', {
    staticClass: "factora"
  }, [_vm._v(_vm._s(_vm.cInfoContent.factor))]), _c('br'), _c('hr'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsStart({
          loc: _vm.cInfoContent.loc,
          bez: "".concat(_vm.cInfoContent.firstname || '-', " ").concat(_vm.cInfoContent.lastname || '-')
        });
      }
    }
  }, [_vm._v("Start für Route")]), _c('br'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsMidroute({
          location: _vm.cInfoContent.loc,
          stopover: true,
          bez: "".concat(_vm.cInfoContent.firstname, " ").concat(_vm.cInfoContent.lastname)
        });
      }
    }
  }, [_vm._v("Zum Weg hinzufügen")]), _c('br'), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.wayPointsEnd({
          loc: _vm.cInfoContent.loc,
          bez: "".concat(_vm.cInfoContent.firstname || '-', " ").concat(_vm.cInfoContent.lastname || '-')
        });
      }
    }
  }, [_vm._v("Ende für Route")])])]) : _vm._e()]) : _vm._e()]), _vm._l(_vm.markerSvObj, function (svMarker, svMarkerIdx) {
    return _c('gmap-marker', {
      key: svMarkerIdx,
      attrs: {
        "icon": svMarker.icon,
        "label": svMarker.label,
        "position": svMarker.position,
        "draggable": false,
        "clickable": true
      },
      on: {
        "click": function click($event) {
          return _vm.clickOnSv(svMarker.sv);
        },
        "mouseover": function mouseover($event) {
          return _vm.hoverOnSv(svMarkerIdx, 'over');
        },
        "mouseout": function mouseout($event) {
          return _vm.hoverOnSv(svMarkerIdx, 'out');
        }
      }
    });
  }), _vm._l(_vm.markerAktObj, function (marker, idx) {
    return _c('gmap-marker', {
      key: idx,
      attrs: {
        "icon": marker.icon,
        "label": marker.label,
        "position": marker.position,
        "draggable": false,
        "clickable": true
      },
      on: {
        "click": function click($event) {
          return _vm.clickOnAkt(marker.content);
        },
        "mouseover": function mouseover($event) {
          return _vm.hoverOnAkt(marker.content, 'over');
        },
        "mouseout": function mouseout($event) {
          return _vm.hoverOnAkt(marker.content, 'out');
        }
      }
    });
  }), _c('gmap-marker', {
    attrs: {
      "position": _vm.mainMarker.center,
      "icon": {
        url: _vm.$root.img.largemarker,
        size: {
          width: 40,
          height: 88,
          f: 'px',
          b: 'px'
        },
        scaledSize: {
          width: 40,
          height: 88,
          f: 'px',
          b: 'px'
        }
      },
      "clickable": true,
      "draggable": true
    },
    on: {
      "click": function click($event) {
        return _vm.clickOnMark();
      },
      "dragend": _vm.dragOnMark
    }
  })], 2), _vm.hoveronmarker ? _c('div', {
    staticClass: "item-hover-info"
  }, [_c('div', [_c('router-link', {
    attrs: {
      "to": "/crmakt/".concat(_vm.infoContent.title)
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.infoContent.art) + " " + _vm._s(_vm.infoContent.commissionNr)), _vm.infoContent.shortName ? _c('span', {
    staticClass: "color-blue"
  }, [_c('br'), _vm._v(_vm._s(_vm.infoContent.shortName))]) : _vm._e()])]), _c('p', [_vm._v(_vm._s(_vm.status[_vm.infoContent.statusOpt]))]), _c('p', [_vm._v("Bearbeiter: " + _vm._s(_vm.infoContent.assignedAppraiserName))]), _vm.infoContent.visitdatum ? _c('p', {
    staticClass: "bold"
  }, [_vm._v("Besichtigung am: " + _vm._s(_vm.$dayjs(_vm.infoContent.visitdatum).format('DD.MM.YYYY - HH:mm')))]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.infoContent.visitAddress))]), _c('p', [_c('small', [_vm._v(_vm._s(_vm.sparten['sparten'][_vm.infoContent.insuranceLineOpt + '']) + " -"), _c('span', [_vm._v(_vm._s(_vm.sparten['details'][_vm.infoContent.insuranceLineDetailsOpt + '']) + " -")]), _c('span', [_vm._v(_vm._s(_vm.sparten['art'][_vm.infoContent.damageKindOpt + '']))])])]), _c('br'), _c('p', [_vm._v("VN: " + _vm._s(_vm.infoContent.insureeName) + ", " + _vm._s(_vm.infoContent.insureeTel))])], 1)]) : _vm.hoveronsv ? _c('div', {
    staticClass: "item-hover-info"
  }, [_c('div', [_c('h4', [_vm._v(_vm._s(_vm.infoContent.firstname) + " " + _vm._s(_vm.infoContent.lastname) + " " + _vm._s(_vm.infoContent.titel))]), _c('p', [_vm._v(_vm._s(_vm.infoContent.plzba) + " " + _vm._s(_vm.infoContent.ortba))]), _c('p', [_vm._v(_vm._s(_vm.infoContent.strasseba))]), _c('p', [_vm._v("email: " + _vm._s(_vm.infoContent.email))]), _c('p', [_vm._v("mobil: " + _vm._s(_vm.infoContent.mobil))]), _c('p', [_vm._v("tel: " + _vm._s(_vm.infoContent.tel))]), _c('div', {
    staticClass: "factorb"
  }, [_vm._v(_vm._s(_vm.infoContent.akts))]), _c('div', {
    staticClass: "factora"
  }, [_vm._v(_vm._s(_vm.infoContent.factor))])])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };